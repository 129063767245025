@import "_variables.scss";
@import "_typography.scss";

.footer {
    transition: .3s all;
    padding: 0 16px;
    max-width: 1920px;
    margin: 15vh auto;
  
    @include breakpoint(md) {
      margin: 20vh auto;
    }
    @include breakpoint(lg) {
      padding: 0 8vw;
    }

    h4 {
        margin: 0;
        font-size: .75rem;
        opacity: .4;

        @include breakpoint(lg) {
            font-size: .92rem;
          }

    }

    .wrapper {
        background: $background-secondary;
        color: $text-light;
        padding: 40px 16px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include breakpoint(sm) {
            padding: 24px;
        }
        @include breakpoint(md) {
            padding: 40px;
        }
        @include breakpoint(lg) {
            padding: 60px;
        }
        @include breakpoint(xl) {
            padding: 80px;
        }

        a {
        color: $text-light;
        }
    }
  
    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 40px;
  
      @include breakpoint(md) {
        flex-direction: row;
        gap: 5%;
        align-items: center;
      }
  
      .text {

        p {
            margin: 10px 0;
        }
        @include breakpoint(md) {
            width: 50%;
          }
      }
    
      .card {
        @include breakpoint(md) {
            width: 45%;
            max-width: 500px;
        }

        .c-header {
            background-color: $surface-secondary-elevated;
            padding: 12px;
            border-radius: 8px 8px 0 0;
            display: flex;
            align-items: center;
            gap: 12px;

            @include breakpoint(lg) {
                padding: 12px;
                gap: 16px;
            }
            @include breakpoint(xl) {
                gap: 24px;
                padding: 24px;
            }

            img {
                border-radius: 200px;
                width: 30%;
                max-width: 100px;
                margin: 0;

                @include breakpoint(lg) {
                    max-width: 160px;
                }


            }
        }
        .c-footer {
            background-color: $surface-secondary;
            border-radius: 0 0 8px 8px;
            padding: 12px;

            @include breakpoint(lg) {
                padding: 16px;
            }
            @include breakpoint(xl) {
                padding: 16px 24px;
            }

            .highlights {
                display: flex;
                align-items: center;
                gap: 16px;
                border-radius: 8px;
                color: $text-light;
                font-size: .75rem;
                flex-direction: row;
                align-items: inherit;

              
                @include breakpoint(xl) {
                  gap: 16px;
                  font-size: .92rem;
                }
              
                .item {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  width: fit-content;

                  img {
                    width: 18px;
                    margin: 0;
              
                    @include breakpoint(xl) {
                      width: 24px;
                    }
                  }
                }
              }
        }
      }
    }

    hr {
        width: 100%;
        border: 1px solid $surface-secondary;
        margin: 0;
    }

    .links {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 12px;
        flex-direction: column-reverse;
        gap: 8px;
        text-align: center;

        @include breakpoint(lg) {
            padding-top: 16px;
            flex-direction: row;
            
        }
    }
  }


  .dark {
    .footer {
      .wrapper {
        background-color: $text-light;
        color: $text-dark;

        a {
          color: $text-dark;
        }

        .c-header {
          background-color: $surface-primary;
        }
        .c-footer {
          background-color: $surface-secondary;
        }

        hr {
          border: 1px solid #dddfdc;
        }
      }
      h4 {
        opacity: .6;
      }
    }
  }
